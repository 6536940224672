export const yanbuDetail = [
	{
		id: 1,
		defalut: require('@/assets/img/眼部素材/产品模特图/YXB1.jpg'),
		active: require('@/assets/img/眼部素材/产品模特图/YXB2.jpg'),
		series: '眼部',
		seriesName: '水粉笔',
		zhName: '酵色「水粉笔」',
		enName: 'Liquid Eye-liner',
		intro: '柔雾哑光质地 奶油感红唇',
		price: '59',
		of: '1',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/眼线液笔/小图/4.jpg'), bigUrl: require('@/assets/img/详情/眼线液笔/大图/04.jpg') },
			{ index: 2, url: require('@/assets/img/详情/眼线液笔/小图/2.jpg'), bigUrl: require('@/assets/img/详情/眼线液笔/大图/02.jpg') },
			{ index: 3, url: require('@/assets/img/详情/眼线液笔/小图/3.jpg'), bigUrl: require('@/assets/img/详情/眼线液笔/大图/03.jpg') },
			{ index: 3, url: require('@/assets/img/详情/眼线液笔/小图/1.jpg'), bigUrl: require('@/assets/img/详情/眼线液笔/大图/01.jpg') },
		],
		color: [
			{
				active: '#a09470',
				name: '#01 听茶',
				span1: '特调橄榄绿',
				span2: '灵动点缀 打破沉闷 ',
				color: require('@/assets/img/详情/眼线液笔/01.jpg')
			},
			{
				active: '#c0888e',
				name: '#02 酒渍',
				span1: '冷调灰紫色',
				span2: '微醺甜酒',
				color: require('@/assets/img/详情/眼线液笔/02.jpg')
			},
			{
				active: '#776362',
				name: '#03 微尘',
				span1: '清透灰蓝调',
				span2: '低调高级',
				color: require('@/assets/img/详情/眼线液笔/03.jpg')
			},
			{
				active: '#b07574',
				name: '#04 烟霞',
				span1: '藕粉豆沙色',
				span2: '眼间温柔',
				color: require('@/assets/img/详情/眼线液笔/04.jpg')
			},
			{
				active: '#c37f67',
				name: '#05 花间',
				span1: '木质苦橙色',
				span2: '暖调烘焙感',
				color: require('@/assets/img/详情/眼线液笔/05.jpg')
			},
			{
				active: '#906f62',
				name: '#06 木偶',
				span1: '松露灰棕色',
				span2: '低饱和下的丰富层次',
				color: require('@/assets/img/详情/眼线液笔/06.jpg')
			}
		]
	},
	{
		id: 2,
		defalut: require('@/assets/img/眼部素材/产品模特图/SCYP1.jpg'),
		active: require('@/assets/img/眼部素材/产品模特图/SCYP2.jpg'),
		series: '眼部',
		seriesName: '丝绸综合盘',
		zhName: '酵色「丝绸综合盘」',
		enName: 'Silky Silk Eyeshadow Palette',
		price: '149',
		of: '9g',
		intro: '独特又感性的橘绿粉撞色',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/丝绸素材/丝绸眼盘/4.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸眼盘/04.jpg') },
			{ index: 2, url: require('@/assets/img/详情/丝绸素材/丝绸眼盘/3.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸眼盘/03.jpg') },
			{ index: 3, url: require('@/assets/img/详情/丝绸素材/丝绸眼盘/2.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸眼盘/02.jpg') },
			{ index: 3, url: require('@/assets/img/详情/丝绸素材/丝绸眼盘/1.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸眼盘/01.jpg') },
		],
		color: [
			{
				active: '#aaab93',
				name: '#09 绿水夏乐',
				span1: '橘绿粉撞色 高闪透亮啫喱',
				span2: '独特又感性 ',
				color: require('@/assets/img/详情/丝绸素材/丝绸眼盘/09.jpg')
			},
		]
	},
	{
		id: 3,
		defalut: require('@/assets/img/眼部素材/产品模特图/BKYP1.jpg'),
		active: require('@/assets/img/眼部素材/产品模特图/BKYP2.jpg'),
		series: '眼部',
		seriesName: '贝壳眼盘',
		zhName: '酵色「贝壳综合盘」',
		enName: 'Spiral Shell Eyeshadow Palette',
		price: '149',
		of: '9g',
		intro: '三重质地闪片 点染灵动双眸',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/贝壳素材/贝壳眼盘/x1.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳眼盘/d1.jpg') },
			{ index: 2, url: require('@/assets/img/详情/贝壳素材/贝壳眼盘/x2.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳眼盘/d2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳眼盘/x3.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳眼盘/d3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳眼盘/x4.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳眼盘/d4.jpg') },
		],
		color: [
			{
				active: '#a47258',
				name: '#07 金棕日出',
				span1: '暖调层次感配色 粉调温暖细闪',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/贝壳眼盘/07.jpg')

			},
			{
				active: '#c58b95',
				name: '#08 粉雾漂流',
				span1: '梦幻粉紫组合 三重质地闪片',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/贝壳眼盘/08.jpg')
			}
		]
	},
	{
		id: 4,
		defalut: require('@/assets/img/眼部素材/产品模特图/HPYP1.jpg'),
		active: require('@/assets/img/眼部素材/产品模特图/HPYP2.jpg'),
		series: '眼部',
		seriesName: '琥珀综合盘',
		zhName: '酵色「琥珀综合盘」',
		enName: 'Tortoise Shell Eyeshadow Palette',
		price: '149',
		of: '9g',
		intro: '复古摩登 透明感纯欲妆',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/琥珀素材/琥珀眼盘/1.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/琥珀眼盘/01.jpg') },
			{ index: 2, url: require('@/assets/img/详情/琥珀素材/琥珀眼盘/2.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/琥珀眼盘/02.jpg') },
			{ index: 3, url: require('@/assets/img/详情/琥珀素材/琥珀眼盘/3.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/琥珀眼盘/03.jpg') },
			{ index: 3, url: require('@/assets/img/详情/琥珀素材/琥珀眼盘/4.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/琥珀眼盘/04.jpg') },
		],
		color: [
			{
				active: '#db893b',
				name: '#04 黄棕琥珀',
				span1: '橘棕色彩 日常实用',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/琥珀眼盘/004.jpg')

			},
			{
				active: '#e5b3a7',
				name: '#05 灰粉琥珀',
				span1: '灰粉裸妆 温柔细腻',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/琥珀眼盘/005.jpg')
			}
		]
	},
	{
		id: 5,
		defalut: require('@/assets/img/眼部素材/产品模特图/Y2KYP1.jpg'),
		active: require('@/assets/img/眼部素材/产品模特图/Y2KYP2.jpg'),
		series: '眼部',
		seriesName: '千禧综合盘',
		intro: '电光金属外壳 高彩火龙果撞色',
		zhName: '酵色「千禧综合盘」',
		enName: 'Future Nostalgia Eyeshadow Palette',
		price: '149',
		of: '9g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/Y2K素材/千禧眼盘/1.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/千禧眼盘/01.jpg') },
			{ index: 2, url: require('@/assets/img/详情/Y2K素材/千禧眼盘/2.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/千禧眼盘/02.jpg') },
			{ index: 3, url: require('@/assets/img/详情/Y2K素材/千禧眼盘/3.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/千禧眼盘/03.jpg') },
			{ index: 3, url: require('@/assets/img/详情/Y2K素材/千禧眼盘/4.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/千禧眼盘/04.jpg') },
		],
		color: [
			{
				active: '#c02b60',
				name: '#06 千禧粉盘',
				span1: '电光金属 高彩撞色',
				span2: ' ',
				color: require('@/assets/img/详情/Y2K素材/千禧眼盘/006.jpg')
			}
		]
	}
]